import React from 'react';
import Plot from 'react-plotly.js';

const GeneBoxPlot = ({ data }) => {
  const boxplotData = data.data;
  const labels = [];
  const tr1Values = [];
  const tr2Values = [];

  boxplotData.forEach((entry) => {
    const { label, tr1, tr2 } = entry;
    const tr1Repeated = tr1.length > 0 ? Array(tr1.length).fill(label) : [];
    //const tr2Repeated = Array(tr2.length).fill(label);

    labels.push(...tr1Repeated);
    tr1Values.push(...tr1);
    tr2Values.push(...tr2);
  });

  const trace1 = {
    x: labels,
    y: tr1Values,
    name: 'Trimester 1',
    type: 'box',
    marker: { color: '#3E6680'}
    };

  const trace2 = {
    x: labels,
    y: tr2Values,
    name: 'Trimester 2',
    type: 'box',
    marker: { color: '#B07156' },
  };

  const layout = {
    title: {
      text: data.title,
      y: 0.925
    },
    margin: {
      t: 50,
      b: 75,
    },
    yaxis: { title: 'Expression' },
    boxmode: 'group',
    font: {
      family: 'Lato, sans-serif',
    },
  };

  const dataPlot = [trace1, trace2];

  return <Plot
    data={dataPlot}
    layout={layout}
    config={{ responsive: true }}
    style={{ width: '100%', height: '100%' }}
  />;
};

export default GeneBoxPlot;