import React from 'react';
import Plot from 'react-plotly.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// https://gl.cs.ut.ee/biit/r-gprofiler2/-/blob/master/R/gprofiler2.R
const goColors = {
  "GO:MF": "#dc3912",
  "GO:BP": "#ff9900",
  "GO:CC": "#109618",
  "KEGG": "#dd4477",
  "REAC": "#3366cc",
  "WP": "#0099c6",
  "TF": "#5574a6",
  "MIRNA": "#22aa99",
  "HPA": "#6633cc",
  "CORUM": "#66aa00",
  "HP": "#990099"
}

export default function BarPlotGO ({ bar_data, barplot_style }) {

  const theme = useTheme();
  const largeViewport = useMediaQuery(theme.breakpoints.up('sm'));

  //const barDataFilt = bar_data.filter
  // Extract the x and y values from bar_data
  const xValues = bar_data.map(x => x.term_name);
  const yValues = bar_data.map(x => x.log10_pvalue);
  const barGroups = bar_data.map(x => x.source);
  const hoverText = bar_data.map(x => `${x.term_id} (${x.term_size})<br />${Number(x.p).toPrecision(4)}`);

  // Create the plot data
  const data = largeViewport ? [
    {
      x: xValues,
      y: yValues,
      type: 'bar',
      transforms: [{
        type: "groupby",
        groups: barGroups,
        styles: Object.keys(goColors).map(k => ({target: k, value: {marker: {color: goColors[k]}}}))
      }],
      customdata: hoverText,
      hovertemplate: '%{customdata}<extra></extra>'
    }
  ] : [
    {
      x: yValues,
      y: xValues,
      type: 'bar',
      orientation: 'h',
      transforms: [{
        type: "groupby",
        groups: barGroups,
        styles: Object.keys(goColors).map(k => ({target: k, value: {marker: {color: goColors[k]}}}))
      }],
      customdata: hoverText,
      hovertemplate: '%{customdata}<extra></extra>'
    }
  ];

  const layout = largeViewport ? {
    title: barplot_style.title,
    xaxis: {
      automargin: true
    },
    yaxis: {
      title: '-log10 p-value'
    },
    font: {
      family: 'Lato, sans-serif'
    },
    autosize: true,
    showlegend: true,
    legend: {
      x: 1,
      xanchor: 'right',
      y: 1
    }
  } : {
    title: barplot_style.title,
    xaxis: {
      title: '-log10 p-value',
      automargin: true
    },
    yaxis: {
      automargin: true,
      autorange: 'reversed'
    },
    font: {
      family: 'Lato, sans-serif'
    },
    autosize: true,
    showlegend: true,
    legend: {
    }
  };

  return <Plot
    data={data}
    layout={layout}
    style={{ width: '100%', height: '600px' }}
    config={{ responsive: true }}
    useResizeHandler={true}
  />;
}
