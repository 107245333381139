import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

// In-house components
import ECard from "./gene/eQTLCard";
import ETable from "./gene/eQTLTable";
import SCard from "./splicing/sQTLCard";
import STable from "./splicing/sQTLTable";
import ICard from "./isoform/isoQTLCard";
import ITable from "./isoform/isoQTLTable"
import NCard from "./network/NetworkCard"
import NTable from "./network/NetworkTable"
import "./GeneSearch.css";

const GeneSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGene, setSelectedGene] = useState(null);
  const [selectedButton, setSelectedButton] = useState("eQTL");
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState({ devmeta: true });

  const studies = [
    { key: "devmeta", displayValue: "Developmental Meta-Analysis" },
  ];

  const [showSearchTable, setShowSearchTable] = useState(true); // Set default to true

  const handleTableIcon = () => {
    setShowSearchTable(!showSearchTable); // Toggle the state when the button is clicked
  };

  const handleToggleClick = (event, newValue) => {
    if (newValue !== null) {
      setSelectedButton(newValue);
      setShowSearchTable(true);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => {
    setChecked({ ...checked, [value]: !checked[value] });
  };

  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  const placeholderList = [
    "ACTR1A",
    "ENSG00000138107",
    "rs2296583",
    "chr1:100001756"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) =>
        (prevIndex + 1) % placeholderList.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [clickedRowData, setClickedRowData] = useState(null);
  const handleRowButtonClick = (rowData) => {
    console.log("Dashboard button clicked for:", rowData);
    setClickedRowData(rowData);
    setShowSearchTable(false);
  };

  //////////////////
  // AUTOCOMPLETE //
  //////////////////
  const [geneOptions, setGeneOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  const fetchGeneSuggestions = async (value) => {
    try {
      const response = await axios.get("/api/genes/", {
        params: {
          term: value,
          page: page,
          per_page: perPage
        }
      });
      if (response.data) {
        setGeneOptions(response.data);
      }
    } catch (error) {
      console.error("Error fetching gene suggestions", error);
    }
  };

  const handleDoubleClick = () => {
    if (!inputValue) {
      setInputValue(placeholderList[placeholderIndex]);
    }
  };

  useEffect(() => {
    debugger;
    if (inputValue) {
      fetchGeneSuggestions(inputValue);
    } else {
      setGeneOptions([]); // Empty the options if there's no input value
    }
  }, [inputValue]);

  ////////////////////
  // QUERY FOR ROWS //
  ////////////////////
  const [rowData, setRowData] = useState([]);
  const fetchGeneData = async (query) => {
    setIsLoading(true); // Set loading state to true at the start of the function
    try {
      let endpoint = ""; // Initialize the endpoint variable
      switch (selectedButton) {
        case "eQTL":
          endpoint = "eqtl";
          break;
        case "sQTL":
          endpoint = "sqtl";
          break;
        case "isoQTL":
          endpoint = "isoqtl";
          break;
        case "network":
          endpoint = "net";
          break;
        default:
          endpoint = "error";
          break;
      }
      if (endpoint) {
        const response = await axios.get(`/api/devmeta/${endpoint}/search/`, {
          params: {
            term: query || ""
          }
        });
        setRowData(response.data);
      }
    } catch (error) {
      console.error("Error fetching gene data", error);
    } finally {
      setIsLoading(false); // Ensure loading state is set to false at the end, whether there's an error or not
    }
  };

  const handleSearchIconClick = async () => {
    if (!isLoading) {
      fetchGeneData(inputValue); // Just call the fetchGeneData function directly
    }
  };

  useEffect(() => {
    fetchGeneData(inputValue);
  }, [selectedButton]);

  return (
    <>
      <Grid container className="nav-bar">
        <Grid xs={6} md="auto" className="logo-left">
          <a href="https://www.med.upenn.edu/"><img
            src="https://www.med.upenn.edu/images/psom_logo_blue.svg"
            alt="Perelman School of Medicine at the University of Pennsylvania"
            width="213"
            height="60"
          /></a>
        </Grid>
        <Grid xs={6} md="auto" className="logo-right">
          <a href="https://gandallab.org"><img
            src="/gandal-lab.png"
            alt="Gandal Lab at Penn/CHOP"
            width="156"
            height="60"
          /></a>
        </Grid>
        <Grid xs={12} md className="gene-search">
          <Box className="search-bar">
            <Autocomplete
              freeSolo
              options={geneOptions}
              inputValue={inputValue}
              sx={{ width: '100%' }}
              getOptionLabel={(option) => `${option.gene_name}`}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  onDoubleClick={handleDoubleClick}
                  placeholder={placeholderList[placeholderIndex]}
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />

            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '10px',
                padding: '5px',
                border: '1px solid rgba(0, 0, 0, 0.12)'  // This gives the thin border
              }}
            >              <IconButton
              onClick={handleSearchIconClick}
            >
                {isLoading ? <CircularProgress size={24} /> : <SearchRoundedIcon />}
              </IconButton>

              <Divider orientation="vertical" flexItem sx={{ height: '40px', marginLeft: '4px', marginRight: '4px' }} />

              <IconButton
                onClick={handleTableIcon}
                color={showSearchTable ? 'primary' : 'default'}
              >
                <TableChartOutlinedIcon />
              </IconButton>
            </Paper>

          </Box>

          <Box className="options-bar">
            <ToggleButtonGroup
              value={selectedButton}
              exclusive
              onChange={handleToggleClick}
              aria-label="gene data selection"
            >
              <ToggleButton value="eQTL" aria-label="eQTL" style={{ textTransform: "none" }}>
                eQTL
              </ToggleButton>
              <ToggleButton value="sQTL" aria-label="sQTL" style={{ textTransform: "none" }}>
                sQTL
              </ToggleButton>
              <ToggleButton value="isoQTL" aria-label="isoQTL" style={{ textTransform: "none" }}>
                isoQTL
              </ToggleButton>
              <ToggleButton value="network" aria-label="network" style={{ textTransform: "none" }}>
                Network
              </ToggleButton>
            </ToggleButtonGroup>

            <IconButton onClick={handleMenuOpen}>
              <FilterListIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {studies.map((study) => (
                <MenuItem key={study.key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[study.key] || false}
                        onChange={() => handleToggle(study.key)}
                      />
                    }
                    label={study.displayValue}
                  />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Grid>
      </Grid>

      {showSearchTable && (
        <div className="table-card">
          {studies
            .filter((study) => checked[study.key])
            .map((study) => {
              if (selectedButton === "eQTL") {
                return <ETable key={study.key} rowData={rowData} onRowButtonClick={handleRowButtonClick} />;
              } else if (selectedButton === "sQTL") {
                return <STable key={study.key} rowData={rowData} onRowButtonClick={handleRowButtonClick} />;
              } else if (selectedButton === "isoQTL") {
                return <ITable key={study.key} rowData={rowData} onRowButtonClick={handleRowButtonClick} />;
              } else {
                return <NTable key={study.key} rowData={rowData} onRowButtonClick={handleRowButtonClick} />;
              }
            })}
        </div>
      )}

      {!showSearchTable && clickedRowData &&
        <div className="info-card">
          {selectedButton === "eQTL" &&
            <ECard key='devmeta' study='devmeta' gene_data={clickedRowData} />}
          {selectedButton === "sQTL" &&
            <SCard key='devmeta' study='devmeta' gene_data={clickedRowData} />}
          {selectedButton === "isoQTL" &&
            <ICard key='devmeta' study='devmeta' gene_data={clickedRowData} />}
          {selectedButton !== "eQTL" && selectedButton !== "sQTL" && selectedButton !== "isoQTL" &&
            <NCard key='devmeta' study='devmeta' gene_data={clickedRowData} />}
        </div>
      }

    </>
  );
};

export default GeneSearch;