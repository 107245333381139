// Basic Imports
import React from "react";
import useSWR from 'swr';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

// Custom Imports
import SpliceBoxPlot from './SpliceBoxPlot';
import SplicePlot from '../splicing/SplicePlot';
import LoadingIndicator from "../ui/LoadingIndicator";

const columns = [
    { field: 'variant_id', headerName: 'variant_id', minWidth: 150, headerAlign: 'left', align: 'left' },
    { field: 'beta', headerName: 'beta', minWidth: 100, headerAlign: 'left', align: 'left' },
    { field: 'log10_pval', headerName: 'log10_pval', minWidth: 150, type: "number", headerAlign: 'left', align: 'left' },
    { field: 'variant_info', headerName: 'chr_pos_ref_alt', minWidth: 150, headerAlign: 'left', align: 'left' },
    { field: 'leafcutter_id', headerName: 'junction_id', minWidth: 300, headerAlign: 'left', align: 'left' },
];

function CustomToolbar(data) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    fileName: `${data.data.gene_name}_sqtl_list.devmeta`,
                    delimiter: ';'
                }}
            />
        </GridToolbarContainer>
    );
}

const fetcher = (...args) => fetch(...args).then(res => res.json());
const SWRConfig = {revalidateOnFocus: false, shouldRetryOnError: false};

export default function SCardExtra({ study, gene_data }) {
    const {
        data: {
            table_data: rowData = [],
            genotypes: genotypeData = [],
            box_plot: boxplotData = [],
            splice_plot: spliceData = [],
            exons: exonData = []
        } = {},
        error,
        isLoading: swrIsLoading
    } = useSWR(`/api/${study}/sqtl/extra/${gene_data.gene_name}`, fetcher, SWRConfig);
    const isLoading = swrIsLoading || error; // load endlessly on error

    return (
        <>
            <Grid xs={12} lg={12} xl={6} sx={{
                maxWidth: '1200px',
                margin: 'auto',
                marginTop: ['20px', '20px', '20px', '80px', '80px']
            }} key="grid1">
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div className="data-grid">
                        <DataGrid
                            rows={rowData}
                            columns={columns}
                            getRowId={(row) => row.leafcutter_id+":"+row.variant_id}
                            slots={{
                                toolbar: () => <CustomToolbar data={gene_data} />,
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 50 },
                                },
                            }}
                            pageSizeOptions={[50, 100]}
                            style={{
                                maxHeight: '600px'
                            }}
                            checkboxSelection
                        />
                    </div>
                )}
            </Grid>
            <Grid sx={{ margin: 'auto', width: '800px' }} key="grid2">
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    spliceData.map((entry, index) => (
                        <Grid>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{entry.genotype}</div>
                                <SplicePlot
                                    exonPositions={exonData}
                                    spliceData={entry.splice_data}
                                />
                            </div>
                        </Grid>
                    ))
                )}

            </Grid>
            <Grid xs={12} lg={4} xl={4} sx={{ minHeight: '400px', minWidth: '700px', maxWidth: '1200px', margin: 'auto' }} key="grid3">
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <SpliceBoxPlot data={boxplotData} />
                )}
            </Grid>
        </>
    );
}
