import React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Jelly } from '@uiball/loaders'

const LoadingIndicator = () => {
  return (
    <Grid xs>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" margin={3}>
      <Jelly size={40} speed={0.9} color="#A28F9D" />
    </Box>
    </Grid>
  );
};

export default LoadingIndicator;