import React, { useEffect, useRef } from 'react';
import LocusZoom from 'locuszoom';
import credibleSets from 'locuszoom/esm/ext/lz-credible-sets';
import 'locuszoom/dist/locuszoom.css';

LocusZoom.use(credibleSets);

const AssociationLZ = LocusZoom.Adapters.get('AssociationLZ');
class CustomAssociation extends AssociationLZ {
    _getURL(request_options) {
        const gene_name = request_options.gene_name;
        return this._url + gene_name;
    }
}

LocusZoom.Adapters.add('CustomAssociation', CustomAssociation);

export default function CrediblePlot ({ gene_data, api_url }) {
    const apiUrl = useRef("");
    const geneName = useRef("");
    const plot = useRef(null);

    useEffect(() => {
        if (plot.current !== null && apiUrl.current == api_url && geneName.current == gene_data.gene_name) {
            return;
        }
        if (plot.current !== null) {
            plot.current.destroy();
        }
        apiUrl.current = api_url;
        geneName.current = gene_data.gene_name;

        const apiBase = "//portaldev.sph.umich.edu/api/v1/";

        var data_sources = new LocusZoom.DataSources()
            .add("assoc", ["CustomAssociation", {
                    url: `${api_url}`,
                    source: 1
                }])
            .add("credset", ["CredibleSetLZ", {
                threshold: 0.95,
                significance_threshold: 7.301
                }])
            .add("ld", ["LDServer", {
                    url: "https://portaldev.sph.umich.edu/ld/"
                }])
            .add("gene", ["GeneLZ", {
                    url: apiBase + "annotation/genes/"
                }])
            .add("recomb", ["RecombLZ", {
                    url: apiBase + "annotation/recomb/results/"
                }])
            .add("constraint", ["GeneConstraintLZ", {
                    url: "https://gnomad.broadinstitute.org/api/"
                }]);

        var layout = LocusZoom.Layouts.get(
            'plot', 
            'association_credible_set',
            {
                responsive_resize: false,
                state: {
                    genome_build: 'GRCh37',
                    gene_name: gene_data.gene_name,
                    chr: typeof gene_data.gene_chr === 'string' ? gene_data.gene_chr.replace(/\D/g, "") : gene_data.gene_chr,
                    start: gene_data.gene_start - 1e6,
                    end: gene_data.gene_stop + 1e6
                }
            }
        );

        plot.current = LocusZoom.populate("#credible-plot", data_sources, layout);
    });
    return (
        <div id="credible-plot"></div>
    );
}
