import React, { useEffect, useRef } from 'react';
import { select, scaleLinear, line, curveCatmullRom } from 'd3';

const IsoformPlot = ({ isoformData }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = select(svgRef.current);

    // Clear any existing content
    svg.selectAll('*').remove();

    // Define the SVG dimensions
    const intronHeight = 10;
    const exonHeight = 30;
    // const refY = 0;
    const plotY = 0;
    const width = 600;
    const height = 50;
    const margin = { top: 11, right: 20, bottom: 20, left: 20 };

    const start_positions = isoformData.referenceExonPositions.concat(isoformData.plotExonPositions).flatMap((item) => item.start);
    const stop_positions = isoformData.referenceExonPositions.concat(isoformData.plotExonPositions).flatMap((item) => item.stop);
    const minPosition = Math.min(...start_positions);
    const maxPosition = Math.max(...stop_positions);

    // Create the x-scale for exon positions
    const xScale = scaleLinear().domain([minPosition, maxPosition]).range([0, width]);

    // Create the plot area
    const plot = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Draw reference exons
    // plot
    //   .append('rect')
    //   .attr('class', 'splice-intron')
    //   .attr('x', xScale(minPosition))
    //   .attr('y', refY + intronHeight)
    //   .attr('width', xScale(maxPosition) - xScale(minPosition))
    //   .attr('height', intronHeight)
    //   .attr('fill', '#ccc');

    // const referenceExons = plot.selectAll('.reference-exon')
    //   .data(isoformData.referenceExonPositions)
    //   .enter()
    //   .append('rect')
    //   .attr('class', 'reference-exon')
    //   .attr('x', d => xScale(d.start))
    //   .attr('y', refY)
    //   .attr('width', d => xScale(d.stop) - xScale(d.start))
    //   .attr('height', exonHeight)
    //   .attr('fill', 'gray');

    // Draw plot exons
    const plotIntrons = plot
      .append('rect')
      .attr('class', 'splice-intron')
      .attr('x', xScale(minPosition))
      .attr('y', plotY + intronHeight)
      .attr('width', xScale(maxPosition) - xScale(minPosition))
      .attr('height', intronHeight)
      .attr('fill', '#ccc');

    const plotExons = plot.selectAll('.plot-exon')
      .data(isoformData.plotExonPositions)
      .enter()
      .append('rect')
      .attr('class', 'plot-exon')
      .attr('x', d => xScale(d.start))
      .attr('y', plotY)
      .attr('width', d => xScale(d.stop) - xScale(d.start))
      .attr('height', exonHeight)
      .attr('fill', d => {
        const matchingReferenceExon = isoformData.referenceExonPositions.find(exon =>
          exon.start === d.start && exon.stop === d.stop
        );
        return matchingReferenceExon ? 'gray' : '#C19A9B';
      });
  }, [isoformData]);

  return (
    <svg ref={svgRef} width="600" height="50"></svg>
  );
};

export default IsoformPlot;