import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { scaleLinear } from 'd3';

export default function NetworkCardExtra({geneData, moduleData, rowData}) {
  rowData[0].index = -1;
  rowData = [rowData[0]];
  const rows = moduleData.nodes.concat(rowData);
  const kMEWidth = 120;
  const kMEScale = scaleLinear().domain([0,1]).range([0,kMEWidth]);
  return (
    <TableContainer component={Paper} sx={{height: 400}}>
      <Table sx={{ minWidth: 650, borderCollapse: 'separate'}}>
        <TableHead>
          <TableRow sx={{'& th': { fontWeight: "bold", position: 'sticky', top: '0', 'zIndex': 2, background: 'white' }}}>
            <TableCell>module</TableCell>
            <TableCell>gene_name</TableCell>
            <TableCell sx={{width: kMEWidth}}>kME</TableCell>
            <TableCell align="right">kME_p</TableCell>
            <TableCell>transcript_name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.index}
              sx={{
                '&:hover': {background: 'whitesmoke'},
                '&:nth-last-of-type(2) td': { border: 0 },
                '&:last-child td': { position: 'sticky', bottom: '0', 'zIndex': 2, background: 'whitesmoke', border: 0, borderTop: '1px solid rgba(224, 224, 224, 1)' }
              }}
            >
              <TableCell>{row.full_dendro_name}</TableCell>
              <TableCell>{row.gene_name}</TableCell>
              <TableCell sx={{textAlign: "right", padding: '8px 16px'}}>
                <div style={{borderLeft: '1px solid black', padding: '8px 0px'}}>
                  <div style={{width: kMEScale(row.kME), backgroundColor: "plum"}}>{row.kME}</div>
                </div>
              </TableCell>
              <TableCell align="right">{row.kME_p && new Number(row.kME_p).toPrecision(4)}</TableCell>
              <TableCell>{row.external_transcript_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
