// Basic Imports
import React from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'locuszoom/dist/locuszoom.css';

// Custom Imports
import StudyCard from "../StudyCard";
import CrediblePlot from '../CrediblePlot';
import SCardExtra from "./sQTLCardExtra";

const study_data = {
    abbr_title: "DevBrain metaQTL",
    full_title: "Developmental brain meta-QTL analysis",
    description: "600 brain samples across 1st and 2nd trimester, uniformly processed for genomic regulation",
    url: "https://pubmed.ncbi.nlm.nih.gov/36945630/"
};

export default function SCard ({ study, gene_data }) {
    const credible_plot_api_url = `/api/${study}/sqtl/`;

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid xs={12}>
                        <div className="study-card">
                            <StudyCard data={study_data} />
                        </div>
                    </Grid>
                    <Grid sx={{ margin: 'auto', width: '800px' }}>
                        <div className="credible-plot">
                            <CrediblePlot gene_data={gene_data} api_url={credible_plot_api_url} />
                        </div>
                    </Grid>
                    <SCardExtra study={study} gene_data={gene_data}/>
                </Grid>
            </CardContent>
        </Card>
    );
}
