import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import InfoIcon from '@mui/icons-material/Info';

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <p style={{marginBottom: "50px"}}>
        <Button variant="outlined" startIcon={<InfoIcon />} onClick={handleClickOpen}>
          About
        </Button>
      </p>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Welcome to the Developing Brain Hub!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <p style={{marginTop: 0}}>
            We are excited to share data from the following publication:
          </p>
          <h2>
            <Link href="https://pubmed.ncbi.nlm.nih.gov/36945630/" target="_blank">
              Cross-ancestry atlas of gene, isoform, and splicing regulation in the developing human brain
            </Link>
          </h2>
          <p>This study provides xQTL maps of the developing human brain and uncovers candidate neuropsychiatric risk mechanisms.</p>
          <h3>Abstract</h3>
          <p>
            Neuropsychiatric genome-wide association (GWAS) studies, including for autism, schizophrenia, and bipolar disorder, are strongly enriched for genomic regulatory elements in the developing brain. However, prioritizing candidate risk genes and mechanisms is challenging without a unified regulatory atlas. Across 672 diverse developing human brain samples, we identified 15,752 genes harboring gene, isoform, and/or splicing quantitative trait loci (xQTLs), and mapped 3,739 QTLs to cellular contexts. Gene expression heritability drops during brain development, and isoform-level regulation, particularly in the second trimester, mediated the largest proportion of GWAS heritability. Via colocalization, we prioritized candidate mechanisms for ~60% of GWAS loci across disorders, exceeding adult brain findings. Finally, we contextualized results within gene/isoform co-expression networks, revealing the comprehensive landscape of genetic regulation in development and disease.
          </p>
          <Paper component="p" elevation={3} style={{padding: "10px 15px"}}>
            <img src="/abstract.png" width="100%" alt="Graphical abstract" />
          </Paper>
          <h3 style={{marginBottom: 0}}>GitHub</h3>
          <List>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://github.com/gandallab/devBrain_xQTL" target="_blank" disablePadding>
                  <ListItemIcon>
                    <GitHubIcon color="primary" />
                  </ListItemIcon>
                  <Link component="span">gandallab/devBrain_xQTL</Link>
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
