import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { Button } from '@mui/material';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

const getColumns = (onRowButtonClick) => ([
  { field: 'transcript_id', headerName: 'Transcript ID', flex: 1.5 },
  {
    field: 'gene_name',
    headerName: 'Gene Name',
    flex: 1,
    renderCell: (params) => (
      <Button
        variant="outlined"
        sx={{
          backgroundColor: '#E88273',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#E26350',
            boxShadow: 'none',
            border: 'none'
          },
          fontWeight: 'bold',
          width: 'fit-content',
          minWidth: '40px',
          color: '#f0eff4',
          border: 'none',
          paddingLeft: '0.4em',
          paddingRight: '0.1em'
        }}
        onClick={() => {
          if (params.row && params.row.gene_name) {
            onRowButtonClick(params.row);
          }
        }}
      >
        {params.value} <KeyboardDoubleArrowRightRoundedIcon />
      </Button>
    ),
  },
  { field: 'variant_id', headerName: 'Variant ID', flex: 1 },
  {
    field: 'log_pvalue',
    headerName: 'LogPval',
    flex: 0.9,
    sortable: true,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) =>
      params.value ? parseFloat(params.value).toFixed(3) : '',
  },
  {
    field: 'slope',
    headerName: 'Beta',
    flex: 0.75,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) =>
      params.value ? parseFloat(params.value).toFixed(3) : '',
  },
  {
    field: 'slope_se',
    headerName: 'Beta SE',
    flex: 0.75,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    valueFormatter: (params) =>
      params.value ? parseFloat(params.value).toFixed(3) : '',
  }
]);

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-root': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#294685',
  },
  '& .MuiDataGrid-columnHeaders .MuiSvgIcon-root': {
    color: '#f0eff4',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#f0eff4',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: '#F9F8F8',
  },
  '& .button-header .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
    color: '#EFCA08'
  }
});

const ITable = ({ rowData, onRowButtonClick }) => {
  return (
    <Card>
      <StyledDataGrid
        rows={rowData}
        columns={getColumns(onRowButtonClick)}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [{ field: 'log_pvalue', sort: 'desc' }],
          }
        }}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

export default ITable;