import React, { useEffect, useRef } from 'react';
import { select, scaleLinear, scalePoint, line, curveCatmullRom } from 'd3';
import './SplicePlot.css';

const SplicePlot = ({ exonPositions, spliceData }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    select(svgRef.current).selectAll('*').remove();

    const exon_height = 30;
    const intron_height = 10;
    const splice_label_height = 20;

    const margin = { top: 15, right: 30, bottom: 15, left: 30 };
    const width = 750 - margin.left - margin.right;
    const height = Math.max(
      exon_height + spliceData.length * splice_label_height, // exon height + splice label heights
      30 + 75 * 2 - margin.top - margin.bottom // just enough space for tooltip
    );

    const svg = select(svgRef.current)
      .append('svg')
      .attr('class', 'splice-plot')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const start_positions = exonPositions.concat(spliceData).flatMap((item) => item.start);
    const stop_positions = exonPositions.concat(spliceData).flatMap((item) => item.stop);
    const minPosition = Math.min(...start_positions);
    const maxPosition = Math.max(...stop_positions);

    const xScale = scaleLinear().domain([minPosition, maxPosition]).range([0, width]);
    
    // intended to originate at exon rect top or bottom and extend upwards or downwards
    const yScale = scalePoint()
      .domain([...Array(Math.ceil(spliceData.length / 2)).keys()])
      .range([splice_label_height / 2, (height - exon_height - splice_label_height) / 2])
      .padding(0.5);

    svg
      .append('rect')
      .attr('class', 'splice-intron')
      .attr('x', xScale(minPosition))
      .attr('y', (height - intron_height) / 2)
      .attr('width', xScale(maxPosition) - xScale(minPosition))
      .attr('height', intron_height)
      .attr('fill', '#ccc');

    const exonSelection = svg.selectAll('.splice-exon')
      .data(exonPositions)
      .enter()
      .append('rect')
      .attr('class', 'splice-exon')
      .attr('x', (exon) => xScale(exon.start))
      .attr('y', (height - exon_height) / 2)
      .attr('width', (exon) => xScale(exon.stop) - xScale(exon.start))
      .attr('height', exon_height)
      .attr('fill', 'steelblue')
      .on('mouseover', showTooltip)
      .on('mouseout', hideTooltip);

    const splice_line = line()
      .curve(curveCatmullRom.alpha(0.5))
      .x(d => d.x)
      .y(d => d.y);

    const splice_line_stroke = 5;

    spliceData.forEach((splice, index) => {
      const midpoint_x = xScale((splice.start + splice.stop) / 2);

      const midpoint_y = index % 2 === 0
        ? height / 2 - exon_height / 2 - yScale(index >> 1)  // upper
        : height / 2 + exon_height / 2 + yScale(index >> 1); // lower

      const points = [
        { x: xScale(splice.start), y: height / 2 },
        { x: midpoint_x, y: midpoint_y },
        { x: xScale(splice.stop), y: height / 2 }
      ];

      const propValue = parseFloat(splice.prop) || 0;

      svg
        .append('path')
        .datum(points)
        .attr('fill', 'none')
        .attr('stroke', 'red')
        .attr('stroke-width', Math.max(1, splice_line_stroke * propValue))
        .attr('d', splice_line);

      svg
        .append('rect')
        .attr('x', midpoint_x - 22.5)
        .attr('y', midpoint_y - (splice_label_height / 2))
        .attr('width', 45)
        .attr('height', splice_label_height)
        .attr('fill', 'rgba(255, 255, 255, 0.85)')
        .attr('stroke', 'white')
        .attr('rx', 5);

      svg
        .append('text')
        .attr('class', 'splice-text')
        .attr('x', midpoint_x)
        .attr('y', midpoint_y)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .text(splice.prop);
    });

    // Tooltip code
    const tooltip = svg.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');

    tooltip
      .append('rect')
      .attr('class', 'tooltip-rect')
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('fill', 'rgba(0, 0, 0, 0.75)');

    tooltip
      .append('text')
      .attr('class', 'tooltip-exon')
      .attr('x', 10)
      .attr('y', 20)
      .attr('fill', '#fff');

    tooltip
      .append('text')
      .attr('class', 'tooltip-number')
      .attr('x', 10)
      .attr('y', 40)
      .attr('fill', '#fff');

      function showTooltip(event, d) {
        tooltip.style('display', 'block');
        const tooltipWidth = d.exon_id.length * 15;
        const tooltipHeight = 50;
        const tooltipOffsetX = tooltipWidth / 2;
        const tooltipOffsetY = 20;
        const rect = this; // Retrieve the current rectangle element
        const rectX = +rect.getAttribute('x'); // Get the X position as a number
        const rectY = +rect.getAttribute('y'); // Get the Y position as a number
        const rectWidth = +rect.getAttribute('width'); // Get the width as a number
        const svgWidth = width;
        let tooltipX, tooltipY;
      
        const rectCenterX = rectX + rectWidth / 2;
        tooltipX = rectCenterX - tooltipOffsetX;
      
        if (tooltipX < 0) {
          tooltipX = 0;
        } else if (tooltipX + tooltipWidth > svgWidth) {
          tooltipX = svgWidth - tooltipWidth;
        }
      
        tooltipY = rectY - tooltipHeight - tooltipOffsetY;
      
        console.log(tooltipX);
      
        tooltip.attr('transform', `translate(${tooltipX}, ${tooltipY})`);
        tooltip.select('.tooltip-rect').attr('width', tooltipWidth).attr('height', tooltipHeight);
        tooltip.select('.tooltip-exon').text(`Exon: ${d.exon_id}`);
        tooltip.select('.tooltip-number').text(`Exon #: ${d.exon_number}`);
      }


    function hideTooltip() {
      tooltip.style('display', 'none');
    }
  }, [exonPositions, spliceData]);

  return <div ref={svgRef}></div>;
};

export default SplicePlot;
