// Basic Imports
import React, { useState, useEffect, useRef } from "react";
import useSWR from 'swr';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useGridApiRef, DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'locuszoom/dist/locuszoom.css';

// Custom Imports
import StudyCard from "../StudyCard";
import CrediblePlot from '../CrediblePlot';
import GeneBoxPlot from './GeneBoxPlot';
import LoadingIndicator from "../ui/LoadingIndicator";

const study_data = {
  abbr_title: "DevBrain metaQTL",
  full_title: "Developmental brain meta-QTL analysis",
  description: "600 brain samples across 1st and 2nd trimester, uniformly processed for genomic regulation",
  url: "https://pubmed.ncbi.nlm.nih.gov/36945630/"
};

const columns = [
  { field: 'variant_id', headerName: 'variant_id', minWidth: 150, headerAlign: 'left', align: 'left' },
  { field: 'beta', headerName: 'beta', minWidth: 100, headerAlign: 'left', align: 'left' },
  { field: 'log_pvalue', headerName: 'log10_pval', minWidth: 150, type: "number", headerAlign: 'left', align: 'left' },
  { field: 'variant_info', headerName: 'chr_pos_ref_alt', minWidth: 150, headerAlign: 'left', align: 'left' }
];

function CustomToolbar(data) {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: `${data.data.gene_name}_eqtl_list.devmeta`,
          delimiter: ';'
        }}
      />
    </GridToolbarContainer>
  );
}

const fetcher = (...args) => fetch(...args).then(res => res.json());
const SWRConfig = { revalidateOnFocus: false, shouldRetryOnError: false };

const ECard = ({ study, gene_data }) => {
  const {
    data: {
      table_data: rowData = [],
      box_plot: boxplotData = []
    } = {},
    error,
    isLoading: swrIsLoading
  } = useSWR(`/api/${study}/eqtl/extra/${gene_data.gene_name}`, fetcher, SWRConfig);
  const isLoading = swrIsLoading || error; // load endlessly on error

  const credible_plot_api_url = `/api/${study}/eqtl/`

  // Ridiculously hacky code to figure out when the DataGrid
  // is actually visible on the page, after which point the
  // apiRef works. Cannot just check apiRef.current because
  // its populated even before the Grid is manipulateable.
  const apiRef = useGridApiRef();
  const [resizeCount, setResizeCount] = useState(0);
  const handleResize = () => {
    setResizeCount(prevCount => prevCount + 1);
    if (resizeCount === 1) {
      if (gene_data && rowData && rowData.length > 0 && apiRef.current) {
        const rowIndex = rowData.findIndex((row) => row.variant_id === gene_data.variant_id);
        if (rowIndex >= 0) {
          const pageSize = 50;
          const pageToGo = Math.floor(rowIndex / pageSize);
          apiRef.current.setPage(pageToGo); // Jump to the page
          apiRef.current.scrollToIndexes({ rowIndex: rowIndex }); // Scroll to the row
          apiRef.current.selectRow(rowData[rowIndex].variant_id, true, true); // Highlight row
        }
      }
    }
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <div className="study-card">
              <StudyCard data={study_data} />
            </div>
          </Grid>
          <Grid sx={{
            maxHeight: '800px',
            margin: 'auto',
            width: '800px'
          }}>
            <div className="credible-plot">
              <CrediblePlot gene_data={gene_data} api_url={credible_plot_api_url} />
            </div>
          </Grid>
          <Grid xs={12} lg={12} xl={12} sx={{
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '20px'
          }}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <div className="data-grid">
                <DataGrid
                  apiRef={apiRef}
                  rows={rowData}
                  columns={columns}
                  getRowId={(row) => row.variant_id}
                  onResize={handleResize}
                  slots={{
                    toolbar: () => <CustomToolbar data={gene_data} />,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 50 },
                    },
                  }}
                  pageSizeOptions={[50, 100]}
                  style={{
                    maxHeight: '400px'
                  }}
                  checkboxSelection
                />
              </div>
            )}
          </Grid>
          <Grid xs={12} sx={{ minHeight: '400px', minWidth: '700px', maxWidth: '1200px', margin: 'auto' }}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <GeneBoxPlot data={boxplotData} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ECard; 