import React from 'react';
import './App.css';
import GeneSearch from './components/GeneSearch';
import ScrollDialog from './components/ui/ScrollDialog';

import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define a custom theme
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <GeneSearch />
        <ScrollDialog />
      </div>
    </ThemeProvider>
  );
}

export default App;
