import React from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Collapse from '@mui/material/Collapse';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// Buttons and Icons
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import PsychologyAltRoundedIcon from '@mui/icons-material/PsychologyAltRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const StudyCard = ({ data }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = () => {
    window.open(data.url, "_blank", "noopener");
  };

  return (
    <Card sx={{ backgroundColor: '#e7ecef', padding: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <Typography component="div" variant="h5">
          {data.abbr_title}
        </Typography>

        <IconButton aria-label="see details" sx={{ marginLeft: '5px' }}>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography variant="body1" color="inherit">Sample Metadata</Typography>
                <Typography variant="body2">
                  PCW 1-13 (TR1): 216 <br/>
                  PCW 14-26 (TR2): 433 <br/>
                  PCW 27-40 (TR3): 5 <br/>
                </Typography>
              </React.Fragment>
            }
          >
          <PsychologyAltRoundedIcon />
          </HtmlTooltip>
        </IconButton>

        <IconButton aria-label="open publication" onClick={handleClick} sx={{ marginLeft: '0px' }}>
          <DescriptionRoundedIcon />
        </IconButton>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph style={{
            textAlign: 'justify',
            padding: '0 20px',
            display: 'inline-block',
            width: '100%',
            boxSizing: 'border-box',
          }}>
            Genomic regulatory elements active in the developing human brain are notably enriched in genetic risk for neuropsychiatric disorders, including autism spectrum disorder (ASD), schizophrenia, and bipolar disorder. However, prioritizing the specific risk genes and candidate molecular mechanisms underlying these genetic enrichments has been hindered by the lack of a single unified large-scale gene regulatory atlas of human brain development. Here, we uniformly process and systematically characterize gene, isoform, and splicing quantitative trait loci (xQTLs) in 672 fetal brain samples from unique subjects across multiple ancestral populations. We identify 15,752 genes harboring a significant xQTL and map 3,739 eQTLs to a specific cellular context. We observe a striking drop in gene expression and splicing heritability as the human brain develops. Isoform-level regulation, particularly in the second trimester, mediates the greatest proportion of heritability across multiple psychiatric GWAS, compared with eQTLs. Via colocalization and TWAS, we prioritize biological mechanisms for ~60% of GWAS loci across five neuropsychiatric disorders, nearly two-fold that observed in the adult brain. Finally, we build a comprehensive set of developmentally regulated gene and isoform co-expression networks capturing unique genetic enrichments across disorders. Together, this work provides a comprehensive view of genetic regulation across human brain development as well as the stage- and cell type-informed mechanistic underpinnings of neuropsychiatric disorders.
          </Typography>
        </CardContent>
      </Collapse>

      {/* <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data.abbr_title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
          {data.description}
        </Typography>
        {data.url && (
          <Button variant="outlined" size="small" onClick={handleClick}>
            Abstract
          </Button>
        )}
      </CardContent> */}
    </Card>
  );
};

export default StudyCard;