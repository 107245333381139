import React from 'react';
import Plot from 'react-plotly.js';

const BarPlot = ({ bar_data, barplot_style }) => {
  // Extract the x and y values from bar_data
  const xValues = bar_data.map((item) => item.enrich_group);
  const yValues = bar_data.map((item) => item.log10_pvalue);

  // Create the plot data
  const data = [
    {
      x: xValues,
      y: yValues,
      type: 'bar',
      marker: { color: barplot_style.fill },
    },
  ];

  return <Plot
    data={data}
    layout={{
        title: barplot_style.title,
        shapes: [
          {
            type: 'line',
            xref: 'paper',
            x0: 0,
            y0: 2.035386,
            x1: 1,
            y1: 2.035386,
            line: {
              color: '#BFA4A4',
              width: 1.5,
              dash: 'dash',
            },
          },
        ],
        xaxis: {
          automargin: true,
          tickangle: 45
        },
        yaxis: {
          title: '-log10 p-value',
          range: [0, 10]
        },
        font: {
          family: 'Lato, sans-serif',
        },
        autosize: true
    }}
    style={{ width: '100%', height: '300px' }}
    config={{ responsive: true }}
    useResizeHandler={true}
  />;
};

export default BarPlot;