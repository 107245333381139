import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const IsoformBoxPlot = ({ data }) => {
  const [chartData, setChartData] = useState([]);

  const colors = ['#507D91', '#3A5B69', '#1D2E35'];

  useEffect(() => {
    const updatedChartData = data.data.map((item, index) => ({
      y: item.data,
      type: 'box',
      name: item.label,
      boxpoints: 'all',
      jitter: 0,
      pointpos: 0,
      marker: {
        color: colors[index % colors.length],
        opacity: 0.2,
      },
      line: {
        color: colors[index % colors.length],
      },
    }));

    setChartData(updatedChartData);
  }, [data]);

  return (
    <Plot
      data={chartData}
      layout={{
        title: data.title,
        yaxis: {
          title: 'Expression',
        },
        font: {
          family: 'Lato, sans-serif',
        },
        margin: {
          t: 40, // Reduce top margin for the title
          r: 40,
          b: 80,
          l: 80,
        },
      }}
      config={{ responsive: true }}
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default IsoformBoxPlot;