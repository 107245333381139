import React, { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as d3 from 'd3';
import './HairballPlot.css';

const HairballPlot = ({ module_data }) => {

  // Plot only the top 10 genes of the first module in the set
  // TO-DO: allow the user to select which network to see, or show the TOP network (not first)
  // module_data = Object.values(module_data)[0];
  const nodesToPlot = 20;
  const module_data_filt = {
    links: module_data.links.filter((link) => link.source <= nodesToPlot-1 && link.target <= nodesToPlot-1),
    nodes: module_data.nodes.filter((node) => node.index <= nodesToPlot-1)
  };

  const ref = useRef();

  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.up('sm')) ? 500 : 320;
  const height = width;

  useEffect(() => {
    // d3 has many side effects including replacing link source/target numbers
    // with object refs, giving nodes x, y, vx, vy properties. let's create
    // deep copies so we can be sure we are starting cleanly.
    const simulation_nodes = JSON.parse(JSON.stringify(module_data_filt.nodes));
    const simulation_links = JSON.parse(JSON.stringify(module_data_filt.links));
    
    //const scale = (10/simulation_nodes.length);
    const svg = d3.select(ref.current).attr('width', width).attr('height', height).attr('viewport', `0 0 ${width} ${height}`);

    const simulation = d3
      .forceSimulation(simulation_nodes)
      .force('link', d3.forceLink(simulation_links))
      .force('charge', d3.forceManyBody().strength(-(width*5) * ((width*0.02)/nodesToPlot)))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .on('tick', tick);

    simulation.alphaTarget(0.2).restart()

    const dragHandler = d3
      .drag()
      .on('start', dragstart)
      .on('drag', dragged)
      .on('end', dragend);

    const link = svg
      .selectAll('.link')
      .data(simulation_links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .style('stroke', (d) => (d.evidence > 0 ? '#BC4B51' : 'rgba(198, 205, 210, 0.2)'))
      .style('stroke-dasharray', (d) => (d.evidence > 0 ? '0' : '4'))
      .style('stroke-width', (d) => 3 * d.weight);

    const node = svg
      .selectAll('.node')
      .data(simulation_nodes)
      .enter()
      .append('circle')
      .attr('class', 'node')
      .attr('r', (d) => width * 0.08 * d.kME * d.kME)
      .call(dragHandler)
      .on('dblclick', dblclick)
      .on('mouseover', showTooltip)
      .on('mouseout', hideTooltip);

    const text = svg
      .selectAll('.label')
      .data(simulation_nodes)
      .enter()
      .append('text')
      .attr('class', 'label')
      .text((d) => d.gene_name)
      .style('font-size', width * .032)
      // .style('font-size', (d) => `${Math.min((10 + (d.gene_name.length * 2)), (2 * d.r))}px`)
      // .style('font-size', (d) => `${(110 * (1/d.gene_name.length))}px`)
      // .style('font-size', (d) => `${(d.r/2)}px`)
      .on('mouseover', showTooltip)
      .on('mouseout', hideTooltip);

    // Code for creating nice-looking tooltip
    // Each display element has to be a class that can
    // be accessed by the mouseOver => showTooltip lifecycle 
    
    const tooltip = svg.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');

    tooltip
      .append('rect')
      // .attr('width', 200)
      // .attr('height', 120)
      .attr('class', 'tooltip-rect')
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('fill', 'rgba(0, 0, 0, 0.75)');

    tooltip
      .append('text')
      .attr('class', 'tooltip-title')
      .attr('x', 10)
      .attr('y', 20)
      .attr('fill', '#fff')
      .attr('font-weight', 'bold')
      .text('Gene Details');

    tooltip
      .append('text')
      .attr('class', 'tooltip-kme')
      .attr('x', 10)
      .attr('y', 40)
      .attr('fill', '#fff');

    tooltip
      .append('text')
      .attr('class', 'tooltip-gene')
      .attr('x', 10)
      .attr('y', 60)
      .attr('fill', '#fff');

    tooltip
      .append('text')
      .attr('class', 'tooltip-id')
      .attr('x', 10)
      .attr('y', 80)
      .attr('fill', '#fff');

    tooltip
      .append('text')
      .attr('class', 'tooltip-module')
      .attr('x', 10)
      .attr('y', 100)
      .attr('fill', '#fff');
    
    function tick() {
      link
        .attr('x1', (d) => d.source.x)
        .attr('y1', (d) => d.source.y)
        .attr('x2', (d) => d.target.x)
        .attr('y2', (d) => d.target.y);

      node.attr('cx', (d) => d.x).attr('cy', (d) => d.y);
      text.attr('x', (d) => d.x).attr('y', (d) => d.y + 5);
    }

    function dragstart(event, node) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      node.fx = node.x;
      node.fy = node.y;

      hideTooltip();

      link.filter(function (one_link) {
        if (one_link.source === node || one_link.target === node) {
          return (one_link)
        }
      }).classed("highlight", true);

      link.filter(function (one_link) {
        if (!(one_link.source === node || one_link.target === node)) {
          return (one_link)
        }
      }).classed("deprioritize", true);
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
      d3.select(this).classed('fixed', true);
      hideTooltip();
    }

    function dragend(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      if (!d3.select(this).classed('double-clicked')) {
        d.fx = d.x;
        d.fy = d.y;
      }
    }

    function dblclick(event, node) {
      d3.select(this).classed('fixed', false).classed('double-clicked', true);
      node.fx = null;
      node.fy = null;

      link.filter(function (one_link) {
        if (one_link.source === node || one_link.target === node) {
          return (one_link)
        }
      }).classed("highlight", false);

      link.filter(function (one_link) {
        if (!(one_link.source === node || one_link.target === node)) {
          return (one_link)
        }
      }).classed("deprioritize", false);
    }

    function showTooltip(event, d) {
      tooltip.style('display', 'block');
      const tooltipWidth = Math.max(d.external_transcript_name.length * 20, 185);
      const tooltipHeight = 120;
      const offsetX = -20;
      const offsetY = 20;
      const nodeX = d.x;
      const nodeY = d.y;
      const svgWidth = width;
      const svgHeight = height;
      let tooltipX, tooltipY;
    
      if (nodeX + tooltipWidth + offsetX > svgWidth) {
        tooltipX = svgWidth - tooltipWidth;
      } else {
        tooltipX = nodeX + offsetX;
      }
    
      if (nodeY + tooltipHeight + offsetY > svgHeight) {
        tooltipY = svgHeight - tooltipHeight;
      } else {
        tooltipY = nodeY + offsetY;
      }
    
      tooltip.attr('transform', `translate(${tooltipX}, ${tooltipY})`);
      tooltip.select('.tooltip-rect').attr('width', tooltipWidth).attr('height', tooltipHeight);
      tooltip.select('.tooltip-gene').text(`Gene: ${d.gene_name}`);
      tooltip.select('.tooltip-module').text(`Module: ${d.full_dendro_name}`);
      tooltip.select('.tooltip-kme').text(`kME: ${d.kME}`);
      tooltip.select('.tooltip-id').text(`Transcript: ${d.external_transcript_name}`);
    }
    
    function hideTooltip() {
      tooltip.style('display', 'none');
    }

    return () => svg.selectAll("*").remove();
  }, [module_data, width]);

  return (
    <div className="hairball-plot" style={{width: width, margin: "0 auto"}}>
      <svg ref={ref} />
    </div>
  );
};

export default HairballPlot;
