// Basic Imports
import React, { useState, useEffect, useRef } from "react";
import useSWR from 'swr';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'locuszoom/dist/locuszoom.css';

// Custom Imports
import StudyCard from "../StudyCard";
import CrediblePlot from '../CrediblePlot';
import BoxPlot from './IsoformBoxPlot';
import IsoformPlot from './IsoformPlot'
import BubblePlot from './BubblePlot'
import LoadingIndicator from "../ui/LoadingIndicator";

const study_data = {
    abbr_title: "DevBrain metaQTL",
    full_title: "Developmental brain meta-QTL analysis",
    description: "600 brain samples across 1st and 2nd trimester, uniformly processed for genomic regulation",
    url: "https://pubmed.ncbi.nlm.nih.gov/36945630/"
};

const columns = [
    { field: 'variant_id', headerName: 'variant_id', minWidth: 150, headerAlign: 'left', align: 'left' },
    { field: 'beta', headerName: 'beta', minWidth: 100, headerAlign: 'left', align: 'left' },
    { field: 'log10_pval', headerName: 'log10_pval', minWidth: 150, type: "number", headerAlign: 'left', align: 'left' },
    { field: 'variant_info', headerName: 'variant_info', minWidth: 150, headerAlign: 'left', align: 'left' },
    { field: 'transcript_id', headerName: 'transcript_id', minWidth: 200, headerAlign: 'left', align: 'left' },
];

function CustomToolbar(data) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    fileName: `${data.data.gene_name}_sqtl_list.devmeta`,
                    delimiter: ';'
                }}
            />
        </GridToolbarContainer>
    );
}

const fetcher = (...args) => fetch(...args).then(res => res.json());
const SWRConfig = { revalidateOnFocus: false, shouldRetryOnError: false };

const ICard = ({ study, gene_data }) => {
    const {
        data: {
            table_data: rowData = [],
            box_plot: boxplotData = [],
            isoform_data: isoformData = [],
            isoform_counts: bubbleData = []
        } = {},
        error,
        isLoading: swrIsLoading
    } = useSWR(`/api/${study}/isoqtl/extra/${gene_data.gene_name}`, fetcher, SWRConfig);
    const isLoading = swrIsLoading || error; // load endlessly on error

    const credible_plot_api_url = `/api/${study}/isoqtl/`

    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid xs={12}>
                        <div className="study-card">
                            <StudyCard data={study_data} />
                        </div>
                    </Grid>
                    <Grid sx={{
                        maxHeight: '800px',
                        margin: 'auto',
                        width: '800px'
                    }}>
                        <div className="credible-plot">
                            <CrediblePlot gene_data={gene_data} api_url={credible_plot_api_url} />
                        </div>
                    </Grid>
                    <Grid xs={12} lg={12} xl={12} sx={{
                        height: '600px',
                        maxWidth: '800px',
                        margin: 'auto',
                        marginTop: [0, 0, 0, '40px', '40px'],
                        marginBottom: [0, 0, 0, '40px', '40px']
                    }}>
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <DataGrid
                                rows={rowData}
                                columns={columns}
                                getRowId={(row) => row.transcript_id + "-" + row.variant_id}
                                slots={{
                                    toolbar: () => <CustomToolbar data={gene_data} />,
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 50 },
                                    },
                                }}
                                pageSizeOptions={[50, 100]}
                                checkboxSelection
                            />
                        )}
                    </Grid>
                    <Grid container spacing={0} sx={{ width: '900px', margin: 'auto' }}>
                        {isLoading ? (
                            <Grid xs={12}>
                                <LoadingIndicator />
                            </Grid>
                        ) : (
                            isoformData.map((entry, index) => (
                                <Grid xs={12} key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '175px' }}>{entry.meta.transcript_id}</div>
                                    <IsoformPlot isoformData={entry.data} />
                                </Grid>
                            ))
                        )}
                    </Grid>

                    <Grid xs sx={{
                        minHeight: '400px',
                        minWidth: '700px',
                        maxWidth: '1200px',
                        margin: 'auto',
                        marginTop: [0, 0, 0, '40px', '40px']
                    }}>
                        {isLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <BoxPlot data={boxplotData} />
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ICard;