// Basic Imports
import React from "react";
import useSWR from 'swr';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'locuszoom/dist/locuszoom.css';

// Custom Imports
import StudyCard from "../StudyCard";
import HairballPlot from './HairballPlot';
import BarPlot from './BarPlot';
import BarPlotGO from './BarPlotGO';
import LoadingIndicator from "../ui/LoadingIndicator";
import NetworkCardExtra from "./NetworkCardExtra";

const study_data = {
  abbr_title: "DevBrain metaQTL",
  full_title: "Developmental brain meta-QTL analysis",
  description: "600 brain samples across 1st and 2nd trimester, uniformly processed for genomic regulation",
  url: "https://pubmed.ncbi.nlm.nih.gov/36945630/"
};

const fetcher = (...args) => fetch(...args).then(res => res.json());
const SWRConfig = { revalidateOnFocus: false, shouldRetryOnError: false };

const NCard = ({ study, gene_data }) => {
  const { data, error } = useSWR(`/api/${study}/net/${gene_data.gene_name}`, fetcher, SWRConfig);

  // Explicitly check if data or error are not defined
  const isLoading = !data && !error;
  const isError = error;

  let moduleOrder = [];
  let rowData = [];
  let hairballData = {};
  let enrichmentData = {};
  let goTermsData = {};

  // Destructure the data only when it exists
  if (data) {
    moduleOrder = data.ordered_modules || [];
    rowData = data.table_data || [];
    hairballData = data.hairball_data || {};
    enrichmentData = data.enrichment_data || {};
    goTermsData = data.go_terms_data || {};
  }

  const moduleToUse = gene_data ? gene_data.assigned_module : null;
  const hairballToUse = moduleToUse ? hairballData[moduleToUse] : [];
  const enrichmentToUse = moduleToUse ? enrichmentData[moduleToUse] : [];
  const goTermsToUse = moduleToUse ? goTermsData[moduleToUse] : [];
  const rowToUse = moduleToUse ? rowData[moduleToUse] : [];

  console.log(moduleToUse);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <div className="study-info">
              <StudyCard data={study_data} />
            </div>
          </Grid>
          <Grid container spacing={2} xs={12}>
            {isLoading && <LoadingIndicator />}
            {isError && <div>Error loading data!</div>}
            {data && (
              <React.Fragment>
                <Grid xs={12} lg="auto" sx={{ textAlign: "center" }}>
                  <div style={{
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    padding: "0.5em 0",
                    color: "#333"
                  }}>
                    {gene_data.full_dendro_name}
                  </div>
                  <HairballPlot module_data={hairballToUse} />
                </Grid>
                <Grid xs={12} lg container spacing={0}>
                  {Object.entries(enrichmentToUse).map(([key, value]) => {
                    const barData = value.sort((a, b) => a.enrich_group.localeCompare(b.enrich_group));

                    // Apply conditional logic
                    let barplot_style = {}

                    if (key === 'cell_type') {
                      barplot_style = {
                        fill: '#885A89',
                        title: 'Cell Type Enrichment (Polioudakis)'
                      }
                    } else if (key === 'gwas_ldsc') {
                      barplot_style = {
                        fill: '#6DA0D9',
                        title: 'GWAS Enrichment (LDSC)'
                      }
                    } else if (key === 'gwas_magma') {
                      barplot_style = {
                        fill: '#E66060',
                        title: 'GWAS Enrichment (MAGMA)'
                      }
                    } else if (key === 'rare_logit') {
                      barplot_style = {
                        fill: '#CC6900',
                        title: 'Rare Variation Enrichment'
                      }
                    }

                    return (
                      <Grid xs={12} sm={6} key={key}>
                        <BarPlot bar_data={barData} barplot_style={barplot_style} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid xs={12}>
                  < BarPlotGO bar_data={goTermsToUse} barplot_style={{
                    title: 'Pathway Enrichment'
                  }} />
                </Grid>
                <Grid xs={12}>
                  <NetworkCardExtra geneData={gene_data} moduleData={hairballToUse} rowData={rowToUse} />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NCard;